import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import parse from "html-react-parser"
import { graphql, Link } from "gatsby"
import Header from "../components/Header/Header"
import MoreTeamDetails from "../components/MoreTeamDetails/MoreTeamDetails"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import Footer from "../components/Footer/Footer"
import { teamEnquiryURL } from "../site/urls"
import noImg from "../images/no-image.png"
import { getWhatsAppURL } from "../site/utils"
import useDeviceMedia from "../hooks/useDeviceMedia"
import SEO from "../components/Seo/seo"

const TeamDetailsTemplate = ({ data }) => {
  const teamDetail = data?.glstrapi.team

  const allTeams = data?.glstrapi?.team?.team_category?.teams?.filter(
    teamValue => teamDetail?.id !== teamValue?.id
  )

  const { isMobile } = useDeviceMedia()

  return (
    <div className="team-details-page">
      <SEO
        title={teamDetail?.name + " | Meet the Team"}
        description={
          "Our agents are always one call away to assist you with all your needs. We have some of the finest real estate agents working at White & Co Real Estate. We have people working here from all over the world with one goal in mind - to cater all your needs!"
        }
        image={teamDetail?.image?.url_sharp?.childImageSharp ? teamDetail.image.url_sharp.childImageSharp.resize : null}
      />
      <Header />

      <div className="team-details-banner"></div>
      <div className="team-details-content">
        <Container>
          {isMobile && <BreadcrumbModule teamsData={teamDetail} tag="team" />}
          <Row>
            <Col xl={6}>
              <div className="team-details-name-wrapper">
                <h1>{teamDetail?.name}</h1>
                <div className="team-details-designation">
                  {teamDetail?.designation}
                </div>
                <ul className="list-inline team-social-wrapper d-xl-block d-none">
                  <li className="list-inline-item">
                    <Link
                      to={teamEnquiryURL}
                      state={{ team: teamDetail }}
                      className="button button-white"
                    >
                      <i className="icon icon-team-email"></i>{" "}
                      <span>Email</span>
                    </Link>
                  </li>
                  {teamDetail?.mobile_no && (
                    <li className="list-inline-item">
                      <a href={`tel:${teamDetail?.mobile_no}`} className="button button-white">
                        <i className="icon icon-team-phone"></i>{" "}
                        <span>{teamDetail?.mobile_no}</span>
                      </a>
                    </li>
                  )}
                  {teamDetail?.mobile_no && (
                    <li className="list-inline-item">
                      <Link
                        to={getWhatsAppURL(teamDetail?.mobile_no)}
                        target="_blank"
                        className="button button-white"
                      >
                        <i className="icon icon-team-whatsapp"></i>{" "}
                        <span>Whatsapp</span>
                      </Link>
                    </li>
                  )}
                </ul>
                <Link
                  to={teamEnquiryURL}
                  state={{ team: teamDetail }}
                  className="team-details-email"
                >
                  {teamDetail?.email}
                </Link>
                <div className="team-details-img-wrapper d-xl-none">
                  {teamDetail?.image?.url ? (
                    <img src={teamDetail?.image?.url} alt={teamDetail?.name} />
                  ) : (
                    <img src={noImg} alt="no image" />
                  )}
                </div>
              </div>
              {teamDetail?.description && (
                <div className="team-details-content-wrapper">
                  <p>{parse(teamDetail?.description)}</p>
                </div>
              )}
              <div className="team-details-split-line"></div>
              {(teamDetail?.languages || teamDetail?.team_specialities?.length > 0) && 
              <div className="team-details-wrapper">
                <h3>Details</h3>
                {teamDetail?.languages && (
                  <div className="team-details-spec-wrapper">
                    <div className="spec-heading">Languages Spoken</div>
                    <div className="spec-details">{teamDetail?.languages}</div>
                  </div>
                )}
                {teamDetail?.team_specialities?.length > 0 && (
                  <div className="team-details-spec-wrapper">
                    <div className="spec-heading">Specialities</div>
                    <div className="spec-details">
                      {teamDetail.team_specialities.map((speciality, i) => (
                        <>
                          <Link to={speciality.url}>{speciality.label}</Link>
                          {i < teamDetail.team_specialities?.length - 1
                            ? ", "
                            : ""}
                        </>
                      ))}
                    </div>
                  </div>
                )}
                {teamDetail?.area_guides?.length > 0 && (
                  <div className="team-details-spec-wrapper pb-0">
                    <div className="spec-heading">Areas Covered</div>
                    <div className="spec-details">
                      {teamDetail.area_guides.map((area_guide, i) => (
                        <>
                          <a href={`/about-us/dubai-communities/`+area_guide?.slug}>{area_guide?.name}</a>
                          {i < teamDetail?.area_guides?.length - 1 ? ", " : ""}
                        </>
                      ))}
                    </div>
                  </div>
                )}
              </div>}
            </Col>
            <Col xl={1}></Col>
            <Col xl={5} className="d-xl-block d-none">
              <div className="team-details-img-wrapper">
                {teamDetail?.image?.url ? (
                  <img src={teamDetail?.image?.url} alt={teamDetail?.name} />
                ) : (
                  <img src={noImg} alt="no image" />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <MoreTeamDetails teamsData={allTeams} />

      <Footer categoryType="category1" />
    </div>
  )
}

export default TeamDetailsTemplate

export const pageQuery = graphql`
  query GetTeamsPage($articleId: ID!) {
    glstrapi {
      team(id: $articleId, publicationState: LIVE) {
        id
        name
        slug
        email
        mobile_no
        languages
        designation
        description
        image {
          url
          url_sharp {
            childImageSharp {
              resize {
                src
                height
                width
              }
            }
          }
        }
        team_specialities {
          label
          url
        }
        area_guides {
          name
          slug
        }
        team_category {
          teams(sort: "sort:asc", limit: 7) {
            id
            name
            email
            designation
            mobile_no
            slug
            ggfx_results {
              id
              content_type
              transforms
              src_import_url
              src_cftle
              field
            }
            tile_image {
              url
            }
            team_category {
              label
            }
          }
        }
      }
    }
  }
`
